import { GraphQlProvider } from 'context/GraphQlProvider/GraphQlProvider';
import React, { useCallback, useState } from 'react';
import { GetCurrentUser } from './GetCurrentUser';
import {
  type UserProviderValue,
  UserStatus,
  type UserProviderData,
  type SetUserProviderData,
} from './UserProvider.types';

const UserContext = React.createContext<UserProviderValue | undefined>(
  undefined
);
UserContext.displayName = 'UserContext';

/**
 * Make information about the current user available for use by wrapping the
 * provided children in a context provider
 */
function UserProvider({ children }: { children: React.ReactNode }) {
  const [userProviderData, setUserProviderData] = useState<UserProviderData>({
    status: UserStatus.NotChecked,
    token: null,
    userData: null,
  });

  const setPartialUserProviderData: SetUserProviderData = useCallback(
    (data) => {
      setUserProviderData({
        token: null,
        userData: null,
        ...data,
      });
    },
    []
  );

  const value = {
    ...userProviderData,
    setUserProviderData: setPartialUserProviderData,
  };

  return (
    <UserContext.Provider value={value}>
      <GraphQlProvider>
        <GetCurrentUser>{children}</GetCurrentUser>
      </GraphQlProvider>
    </UserContext.Provider>
  );
}

export { UserContext, UserProvider };
