import { useUserContext } from 'context/UserProvider/useUserContext';
import { accountManagerBaseUrl } from 'utils/accountManagerBaseUrl';
import { carrotDomain } from 'utils/carrotDomain';

const accountManagerGraphQlUrl = `${accountManagerBaseUrl(carrotDomain())}/graphql`;

function useAuthorizationHeader() {
  const { token } = useUserContext();
  const requestHeaders = token
    ? { authorization: `Bearer ${token}` }
    : undefined;

  return requestHeaders;
}

export { accountManagerGraphQlUrl, useAuthorizationHeader };
