import cx from 'clsx';
import { ButtonSize, ButtonVariant } from './Button';

export function buttonClasses({
  size = 'lg',
  variant = 'primary',
  minWidth = true,
}: {
  size?: ButtonSize;
  variant?: ButtonVariant;
  minWidth?: boolean;
} = {}) {
  return cx(
    'inline-flex select-none items-center justify-center gap-3 whitespace-nowrap border font-bold shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50',
    sizeClasses(size),
    variantClasses(variant),
    minWidth && 'min-w-[150px]'
  );
}

function sizeClasses(size: ButtonSize) {
  return /*tw*/ {
    xs: 'rounded px-3 py-2 text-xs leading-4',
    sm: 'rounded-md px-3 py-2 text-sm leading-4',
    base: 'rounded-md px-4 py-2 text-sm leading-5',
    lg: 'rounded-md px-4 py-2 text-base leading-6',
    xl: 'rounded-md px-6 py-3 text-base leading-6',
  }[size];
}

function variantClasses(variant: ButtonVariant) {
  return /*tw*/ {
    primary:
      'border-transparent bg-indigo-700 text-white hover:bg-indigo-800 disabled:hover:bg-indigo-700',
    secondary:
      'border-gray-400 bg-white text-gray-700 hover:bg-gray-100 focus:border-gray-300 disabled:hover:bg-white',
    accent:
      'border-transparent bg-orange-600 text-white hover:bg-orange-700 disabled:hover:bg-orange-600',
  }[variant];
}
