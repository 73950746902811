import { assignLocation } from '#utils/assignLocation';
import { useMixpanelContext } from 'context/MixpanelProvider/useMixpanelContext';
import { OverridedMixpanel } from 'mixpanel-browser';
import React from 'react';
import {
  Link as ReactRouterLink,
  type LinkProps as ReactRouterLinkProps,
} from 'react-router-dom';
import { isUrl } from './isUrl';

interface LinkProps extends ReactRouterLinkProps {
  trackingName?: string;
  trackingMeta?: Record<string, unknown>;
}

/**
 * Render a standard link or a client-side routing link, with some helpful
 * extras to make solving common problems easier.
 *
 * This component can be used directly, but is primarily intended as a basis
 * for other components that need certain functionality.
 */
const Link = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const mixpanel = useMixpanelContext();
  const trackClick = getTrackingHandler(mixpanel, props);

  // The tracking props aren't valid attributes to pass on to the anchor, so
  // remove them.
  const { trackingName, trackingMeta, ...rest } = props;
  return (
    <ReactRouterLink
      ref={ref}
      {...rest}
      rel={rest.target === '_blank' ? 'noreferrer' : rest.rel}
      onClick={trackClick}
    />
  );
});
Link.displayName = 'Link';

function getTrackingHandler(
  mixpanel: OverridedMixpanel,
  { to, target, trackingName, trackingMeta }: LinkProps
) {
  if (!trackingName) {
    return undefined;
  }

  const trackingArgs: Parameters<typeof mixpanel.track> = [
    trackingName,
    trackingMeta,
  ];

  return (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // Prevent navigation during test runs when requested
    if (window.carrot.preventTestNavigation) {
      e.preventDefault();
    }

    /**
     * Prevent navigation until the Mixpanel tracking has been sent.
     *
     * This can happen when the link is to an external resource (not a
     * client-side route) and the link is not being opened in a new tab/window.
     * This creates a race condition where the current page may be unloaded to
     * navigate to the external page before the tracking occurs.
     *
     * We don't need to worry about this if the link is to a client-side route,
     * is set to open in a new tab via its target, or if the user invoked the
     * link while holding a modifier key (ctrl/shift/cmd). In these cases, the
     * page is not unloaded.
     */
    const willOpenInNewTab =
      target === '_blank' || e.ctrlKey || e.metaKey || e.shiftKey;
    const raceCondition = isUrl(to) && !willOpenInNewTab;
    if (raceCondition) {
      e.preventDefault();
      trackingArgs.push(
        // Disable Mixpanel's default batching/queuing and send the event
        // immediately. This ensures the navigation callback is run after the
        // network request has actually been sent, rather than after the event
        // has been batched.
        // See https://github.com/mixpanel/mixpanel-js/issues/433#issuecomment-2231296633.
        { send_immediately: true },
        // Provide Mixpanel with a callback that navigates to the link
        // destination after the tracking event has been sent.
        () => assignLocation(to)
      );
    }

    mixpanel.track(...trackingArgs);
  };
}

export { Link };
export type { LinkProps };
