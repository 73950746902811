import { useScript } from '#Root/ThirdPartyScriptLoader/useScript';
import { useUserContext } from 'context/UserProvider/useUserContext';
import { userLoadingComplete } from 'context/UserProvider/UserProvider.types';
import { carrotDomain } from 'utils/carrotDomain';
import { carrotEnvironment } from 'utils/carrotEnvironment';

const environment = carrotEnvironment(carrotDomain());
const facebookPixelId =
  environment === 'production' ? '792870337463321' : '190563102420564';

// Render base tracking scripts for everyone, logged out or logged in, except for super admins.
function BaseTrackingScripts({ children }: { children: React.ReactNode }) {
  const userContext = useUserContext();

  return (
    <>
      {children}
      <div data-testid="create-account-base-scripts" className="hidden">
        {userLoadingComplete(userContext) &&
          !userContext.userData?.superAdmin && <Scripts />}
      </div>
    </>
  );
}

function Scripts() {
  // We already have gtag loaded for Google Analytics, so we just need to
  // configure it for AdWords.
  useScript({
    id: 'adwords-configure',
    body: `gtag('config', 'AW-957567596');`,
  });

  useScript({
    id: 'facebook-pixel-base-code',
    body: `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${facebookPixelId}');
      fbq('track', 'PageView');`,
  });

  /* eslint-disable no-useless-escape */
  useScript({
    /* WARN: the script uses this id. Do not change it. */
    id: 'vwoCode',
    body: `
      window._vwo_code=window._vwo_code || (function() {
      var account_id=157223,
      version = 1.5,
      settings_tolerance=2000,
      library_tolerance=2500,
      use_existing_jquery=false,
      is_spa=1,
      hide_element='body',
      hide_element_style = 'opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important',
      /* DO NOT EDIT BELOW THIS LINE */
      f=false,d=document,vwoCodeEl=d.querySelector('#vwoCode'),code={use_existing_jquery:function(){return use_existing_jquery},library_tolerance:function(){return library_tolerance},hide_element_style:function(){return'{'+hide_element_style+'}'},finish:function(){if(!f){f=true;var e=d.getElementById('_vis_opt_path_hides');if(e)e.parentNode.removeChild(e)}},finished:function(){return f},load:function(e){var t=d.createElement('script');t.fetchPriority='high';t.src=e;t.type='text/javascript';t.onerror=function(){_vwo_code.finish()};d.getElementsByTagName('head')[0].appendChild(t)},getVersion:function(){return version},getMatchedCookies:function(e){var t=[];if(document.cookie){t=document.cookie.match(e)||[]}return t},getCombinationCookie:function(){var e=code.getMatchedCookies(/(?:^|;)\s?(_vis_opt_exp_\d+_combi=[^;$]*)/gi);e=e.map(function(e){try{var t=decodeURIComponent(e);if(!/_vis_opt_exp_\d+_combi=(?:\d+,?)+\s*$/.test(t)){return''}return t}catch(e){return''}});var i=[];e.forEach(function(e){var t=e.match(/([\d,]+)/g);t&&i.push(t.join('-'))});return i.join('|')},init:function(){if(d.URL.indexOf('__vwo_disable__')>-1)return;window.settings_timer=setTimeout(function(){_vwo_code.finish()},settings_tolerance);var e=d.createElement('style'),t=hide_element?hide_element+'{'+hide_element_style+'}':'',i=d.getElementsByTagName('head')[0];e.setAttribute('id','_vis_opt_path_hides');vwoCodeEl&&e.setAttribute('nonce',vwoCodeEl.nonce);e.setAttribute('type','text/css');if(e.styleSheet)e.styleSheet.cssText=t;else e.appendChild(d.createTextNode(t));i.appendChild(e);var n=this.getCombinationCookie();this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+ +is_spa+'&vn='+version+(n?'&c='+n:''));return settings_timer}};window._vwo_settings_timer = code.init();return code;}());
    `,
  });
  /* eslint-enable no-useless-escape */

  return null;
}

export { BaseTrackingScripts };
