import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { accountManagerBaseUrl } from 'utils/accountManagerBaseUrl';
import { carrotDomain } from 'utils/carrotDomain';

const graphQlEndpoint = `${accountManagerBaseUrl(carrotDomain())}/graphql`;
const httpLink = createHttpLink({
  uri: graphQlEndpoint,
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

export { client, httpLink };
