import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { resolveValue, type Toast, Toaster, ToastIcon } from 'react-hot-toast';
import colors from 'turnip/colors.json';

/**
 * Built using react-hot-toast.
 * We've customized the notifications based on TailwindUI, and the toast
 * language has been replaced with a more intuitive name.
 */
export function Notifications() {
  return (
    <Toaster
      position="top-right"
      toastOptions={{
        success: {
          iconTheme: { primary: colors.green[500], secondary: colors.white },
        },
        error: {
          iconTheme: { primary: colors.red[500], secondary: colors.white },
        },
      }}
    >
      {(toast) => <Notification {...toast} />}
    </Toaster>
  );
}

function Notification(notification: Toast): JSX.Element {
  return (
    <Transition
      appear
      show={notification.visible}
      as={Fragment}
      enter="transform transition duration-300 ease-out"
      enterFrom="translate-y-2 opacity-0 sm:translate-x-32 sm:translate-y-0"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition duration-100 ease-in"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="p-4">
          <div className="flex items-start">
            <Icon {...notification} />

            <Message {...notification} />
          </div>
        </div>
      </div>
    </Transition>
  );
}

function Icon(notification: Toast) {
  return notification.type === 'loading' ? (
    <FontAwesomeIcon icon={faSpinner} size="lg" spin />
  ) : (
    <ToastIcon toast={notification} />
  );
}

function Message(notification: Toast) {
  const message =
    typeof notification.message === 'string' ? (
      <p className="text-sm font-bold text-gray-900">{notification.message}</p>
    ) : (
      resolveValue(notification.message, notification)
    );

  return (
    <div className="ml-3 w-0 flex-1" {...notification.ariaProps}>
      {message}
    </div>
  );
}
