import { ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useUserContext } from 'context/UserProvider/useUserContext';
import { client, httpLink } from './client';

export function GraphQlProvider({ children }: { children: React.ReactNode }) {
  const { token } = useUserContext();

  const authenticationHeader = token
    ? { authorization: `Bearer ${token}` }
    : {};
  const authenticationLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...authenticationHeader,
      },
    };
  });
  client.setLink(authenticationLink.concat(httpLink));

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
