import { carrotDomain } from './carrotDomain';

const carrotUrl = `https://${carrotDomain()}`;
const appUrl = `https://app.${carrotDomain()}`;
const helpUrl = `https://help.${carrotDomain()}`;
const communityUrl = `https://community.${carrotDomain()}`;
const supportUrl = `${helpUrl}/support/`;
const pricingUrl = `${carrotUrl}/pricing/`;
const termsUrl = `${carrotUrl}/terms/`;
const privacyPolicyUrl = `${carrotUrl}/privacy/`;
const goUrl = `https://go.${carrotDomain()}`;
const marketplaceUrl = `https://marketplace.${carrotDomain()}`;
const superAdminUrl = `https://admin.${carrotDomain()}`;
const contentUrl = `https://content.${carrotDomain()}`;
const carrotCrmUrl = `https://crm.${carrotDomain()}`;

const authenticationEndpoint = `${appUrl}/wp-json/carrot/v1/auth-api/`;

function loginUrl(redirectUrl?: string) {
  if (redirectUrl) {
    return `${appUrl}/?redirect_to=${encodeURIComponent(redirectUrl)}`;
  }

  return appUrl;
}

function deepLinkToApp(path: string) {
  if (!path.startsWith('/')) throw new Error('path must start with /');
  return `${appUrl}/goto/?t=${appUrl}/account/site/*(ID)*${path}`;
}

function upsellPath(product: string) {
  return `/account/billing/edit?product=${product}`;
}

/**
 * @param siteId - WordPress site ID
 * @param path - Single site app path and query params, if any
 * @returns URL to single site app page
 */
function singleSiteAppUrl(siteId: number, path = '') {
  const pathWithLeadingSlash = path.replace(/^([^/])/, '/$1');
  return `${appUrl}/account/site/${siteId}${pathWithLeadingSlash}`;
}

/**
 * @param domain - WordPress site domain
 * @param path - WP Admin page URL with query params, if any
 * @returns URL to WP Admin page
 */
function wpAdminUrl(domain: string, path = '') {
  return `https://${domain}/wp-admin/${path}`.replace(
    'wp-admin//',
    'wp-admin/'
  );
}

/**
 * @param siteId - WordPress site ID
 * @returns Object with URLs to the AMUI routes for the given site
 */
function routes(siteId: number) {
  return {
    /** `/account/site/:siteId/analytics` */
    analytics: `/account/site/${siteId}/analytics`,
    /** `/account/site/:siteId/seo` */
    seo: `/account/site/${siteId}/seo`,
    /** `/account/site/:siteId/seo/domain-overview` */
    domainOverview: `/account/site/${siteId}/seo/domain-overview`,
    /** `/account/site/:siteId/seo/backlink-analysis` */
    backlinkAnalysis: `/account/site/${siteId}/seo/backlink-analysis`,
    /** `/account/site/:siteId/seo/search-performance */
    searchPerformance: `/account/site/${siteId}/seo/search-performance`,
    /** `/account/site/:siteId/seo/site-audit` */
    siteAudit: `/account/site/${siteId}/seo/site-audit`,
    /** `/account/site/:siteId/content-overview` */
    contentOverview: `/account/site/${siteId}/content-overview`,
  };
}

const features = {
  seoTools: `${carrotUrl}/features/seo-tools/`,
};

export {
  carrotUrl,
  appUrl,
  helpUrl,
  communityUrl,
  supportUrl,
  pricingUrl,
  termsUrl,
  privacyPolicyUrl,
  goUrl,
  marketplaceUrl,
  superAdminUrl,
  contentUrl,
  carrotCrmUrl,
  authenticationEndpoint,
  loginUrl,
  deepLinkToApp,
  upsellPath,
  singleSiteAppUrl,
  wpAdminUrl,
  routes,
  features,
};
