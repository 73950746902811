import { useUserContext } from 'context/UserProvider/useUserContext';
import type { FullyLoadedUserProviderData } from 'context/UserProvider/UserProvider.types';
import dayjs from 'dayjs';
import { useCustomerPortalUrl } from 'hooks/useCustomerPortalUrl.am';
import { ContextualLink } from 'turnip/ContextualLink/ContextualLink';
import { AuthenticatedNavBar } from 'turnip/AuthenticatedNavBar/AuthenticatedNavBar';
import { Banner } from 'turnip/Banner/Banner';
import { Offset } from 'turnip/Offset/Offset';
import type { Maybe } from 'types/Utilities';
import { linkOrSupportLink } from 'utils/linkOrSupportLink';
import { SingleSiteNav } from 'turnip/SingleSiteNav/SingleSiteNav';
import { useParams } from 'react-router-dom';
import { Stack } from 'turnip/Stack/Stack';
import { useWpBlogQuery } from 'graphql/useWpBlogQuery.am';

export function PageWithAuthenticatedNavs({
  showSingleSiteNav = false,
  children,
}: {
  showSingleSiteNav?: boolean;
  children: React.ReactNode;
}) {
  const {
    userData: {
      avatarUrl,
      carrotAccountStatus,
      currentSubscription,
      wordpressUserId,
      superAdmin,
    },
  } = useUserContext() as FullyLoadedUserProviderData; // This should only be rendered if the user is fully loaded
  const params = useParams();
  const siteId = params.siteId && parseInt(params.siteId);

  return (
    <>
      <AuthenticatedNavBar avatarUrl={avatarUrl} />

      {carrotAccountStatus === 'active_past_due' && (
        <PastDueBanner
          pastDueCancelAtDate={currentSubscription?.pastDueCancelAtDate}
          wordpressUserId={wordpressUserId}
        />
      )}

      {showSingleSiteNav && siteId ? (
        <Offset top={14}>
          <SideNav siteId={siteId} isSuperAdmin={!!superAdmin}>
            {children}
          </SideNav>
        </Offset>
      ) : (
        <Offset top={24}>{children}</Offset>
      )}
    </>
  );
}

function SideNav({
  siteId,
  isSuperAdmin,
  children,
}: {
  siteId: number;
  isSuperAdmin: boolean;
  children: React.ReactNode;
}) {
  const { data, isPending } = useWpBlogQuery(siteId);
  if (isPending) return null;

  const subdomain = data?.wpBlog?.subdomain;

  return (
    <Stack direction="horizontal">
      {subdomain && (
        <div className="hidden w-52 shrink-0 pl-9 pr-5 lg:block">
          <SingleSiteNav
            siteId={siteId}
            domain={subdomain}
            isSuperAdmin={isSuperAdmin}
            owner={data.wpBlog?.owner}
            category={data.wpBlog?.category}
          />
        </div>
      )}

      <div className="w-full min-w-0">{children}</div>
    </Stack>
  );
}

function PastDueBanner({
  pastDueCancelAtDate,
  wordpressUserId,
}: {
  pastDueCancelAtDate: Maybe<string>;
  wordpressUserId: number;
}) {
  const { customerPortalUrl, loading } = useCustomerPortalUrl(wordpressUserId);
  if (loading) return null;

  const [linkHref, linkText] = linkOrSupportLink(
    customerPortalUrl,
    'update your billing details',
    'contact support'
  );
  const bannerLink = <ContextualLink to={linkHref}>{linkText}</ContextualLink>;

  const formattedCancelDate = dayjs(pastDueCancelAtDate).format('MMMM D, YYYY');
  const dateText = pastDueCancelAtDate
    ? ` before ${formattedCancelDate} `
    : ' ';
  const bannerEndText = 'so you don’t miss any new leads or deals!';

  if (!customerPortalUrl) {
    return (
      <Banner>
        Please {bannerLink} to update your billing {dateText} {bannerEndText}
      </Banner>
    );
  }

  return (
    <Banner>
      Please {bannerLink}
      {dateText}
      {bannerEndText}
    </Banner>
  );
}
