import { useMixpanelContext } from 'context/MixpanelProvider/useMixpanelContext';
import React, { type ForwardedRef } from 'react';
import { Link, LinkProps } from 'turnip/Link/Link';
import { buttonClasses } from './classes';

type ButtonSize = 'xs' | 'sm' | 'base' | 'lg' | 'xl';
type ButtonVariant = 'primary' | 'secondary' | 'accent';
type BaseButtonProps = {
  children: React.ReactNode;
  size?: ButtonSize;
  variant?: ButtonVariant;
  icon?: JSX.Element;
  iconPlacement?: 'right' | 'left';
  minWidth?: boolean;
};

type ButtonButtonProps = {
  as?: 'button';
  type?: 'button' | 'submit';
  onClick?: ((e?: React.MouseEvent<HTMLButtonElement>) => void) | (() => void);
  disabled?: boolean;
} & Pick<LinkProps, 'trackingMeta' | 'trackingName'>;

type LinkButtonProps = {
  as: 'link';
} & LinkProps;

type ButtonProps = BaseButtonProps & (ButtonButtonProps | LinkButtonProps);

const Button = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>(
  (
    {
      size = 'lg',
      variant = 'primary',
      icon,
      iconPlacement = 'right',
      children,
      minWidth = true,
      trackingName,
      trackingMeta,
      ...props
    },
    ref
  ) => {
    const mixpanel = useMixpanelContext();
    const classNames = buttonClasses({ size, variant, minWidth });

    const contents = (
      <ButtonContents icon={icon} iconPlacement={iconPlacement}>
        {children}
      </ButtonContents>
    );

    switch (props.as) {
      case 'link': {
        return (
          <Link
            className={classNames}
            trackingName={trackingName}
            trackingMeta={trackingMeta}
            {...props}
            ref={ref as ForwardedRef<HTMLAnchorElement>}
          >
            {contents}
          </Link>
        );
      }

      case 'button':
      default: {
        return (
          <button
            className={classNames}
            {...props}
            // Fire tracking event, if specified, then call the original handler.
            onClick={(e) => {
              if (trackingName) {
                mixpanel.track(trackingName, trackingMeta);
              }
              props.onClick?.(e);
            }}
            ref={ref as ForwardedRef<HTMLButtonElement>}
          >
            {contents}
          </button>
        );
      }
    }
  }
);
Button.displayName = 'Button';

function ButtonContents({
  icon,
  iconPlacement,
  children,
}: Pick<BaseButtonProps, 'children' | 'icon' | 'iconPlacement'>) {
  if (!icon) {
    return <>{children}</>;
  }

  return (
    <>
      {iconPlacement === 'left' && icon}
      {children}
      {iconPlacement === 'right' && icon}
    </>
  );
}

export { Button };
export type { ButtonProps, ButtonSize, ButtonVariant };
