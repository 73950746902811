import cx from 'clsx';
import { backgroundClasses } from '../backgroundClasses';

/** Starter classes for the typical submenu button appearance */
export function exampleButtonClasses(open: boolean) {
  return cx(
    'relative flex min-h-full items-center px-2 py-[6px] text-white md:px-3',
    backgroundClasses(open)
  );
}
