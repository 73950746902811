import { useContext } from 'react';
import { UserProviderValue } from './UserProvider.types';
import { UserContext } from './UserProvider';

/**
 * Get information about the current user
 */
export function useUserContext(): UserProviderValue {
  const userContext = useContext(UserContext);
  if (userContext === undefined) {
    throw new Error('useUserContext must be used within an UserProvider.');
  }

  return userContext;
}
