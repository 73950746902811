import React from 'react';
import { MixpanelContext } from './MixpanelProvider';

export function useMixpanelContext() {
  const context = React.useContext(MixpanelContext);
  if (context === undefined) {
    throw new Error(
      'useMixpanelContext must be used within a MixpanelProvider'
    );
  }

  return context;
}
